<template>
  <div class="ecu-order-info">
    <div
      v-for="(img, i) in goodsImgList"
      :key="i"
      class="ecu-order-info__img"
      :style="{ '--margin-left': i > 0 ? `${3/37.5}rem` : '0' }"
    >
      <img
        :src="img"
        alt=""
        class="ecu-order-info__img-content"
      />
      <span
        v-if="count > 3 && i === 2"
        class="ecu-order-info__img-overlay"
      >
        +{{ overCount }}
      </span>
    </div>
    <span
      v-if="total"
      class="ecu-order-info__total"
    >
      {{ total }}
    </span>
    <span
      v-if="total && savePriceAmountWithSymbol"
      class="ecu-order-info__divider"
    ></span>
    <div
      v-if="savePriceAmountWithSymbol"
      class="ecu-order-info__saved"
    >
      <span class="ecu-order-info__saved-label">
        {{ language.SHEIN_KEY_PWA_30913 }}:
      </span>
      <span class="ecu-order-info__saved-value">
        {{ savePriceAmountWithSymbol }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  orderInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})
const goodsImgList = computed(() => props.orderInfo?.goodsImgList?.slice(0, 3) || [])
const count = computed(() => props.orderInfo?.goodsImgList?.length || 0)
const overCount = computed(() => {
  if (props.orderInfo?.goodsNum) {
    return Math.min(props.orderInfo?.goodsNum - 2, 99)
  }
  return ''
})
const total = computed(() => {
  return `${props.language.SHEIN_KEY_PWA_30842}: ${props.orderInfo?.totalPrice?.amountWithSymbol}`
})
const savePriceAmountWithSymbol = computed(() => props.orderInfo?.savedPrice?.amount > 0 ? props.orderInfo?.savedPrice?.amountWithSymbol : '')
</script>

<style lang="less" scoped>
.ecu-order-info {
  margin-top: 10/37.5rem;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 36/37.5rem;
  padding: 6/37.5rem 8/37.5rem;
  background: #fff;
  &::before {
    content: '';
    position: absolute;
    top: -4/37.5rem;
    left: 38/37.5rem;
    width: 16/37.5rem;
    height: 16/37.5rem;
    transform: rotate(45deg);
    background: #fff;
  }
  .ecu-order-info__img {
    flex-shrink: 0;
    position: relative;
    width: 24/37.5rem;
    height: 24/37.5rem;
    z-index: 1;
    margin-left: var(--margin-left);
    // &:not(:last-child) {
    //   margin-right: 3/37.5rem;
    // }
  }
  .ecu-order-info__img-content {
    width: 100%;
    height: 100%;
  }
  .ecu-order-info__img-overlay {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 10px;
  }
  .ecu-order-info__total {
    position: relative;
    flex-shrink: 0;
    margin-left: 10/37.5rem;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }
  .ecu-order-info__divider {
    margin: 0 6/37.5rem;
    width: 1/37.5rem;
    height: 10/37.5rem;
    background: rgba(34, 34, 34, 0.30);
  }
  .ecu-order-info__saved {
    position: relative;
    display: inline-flex;
    min-width: 0;
    font-size: 12px;
    color: #FA6338;
    z-index: 1;
  }
  .ecu-order-info__saved-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ecu-order-info__saved-value {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
