<template>
  <div
    class="ecu-order"
    @click="onClick"
  >
    <div class="ecu-order__content">
      <BenefitInfo
        :order-info="orderInfo"
        :language="language"
        type="0"
        @viewOrder="onClick"
      />
      <EmptyCartUnpaidOrderInfo
        :order-info="orderInfo"
        :language="language"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import BenefitInfo from './BenefitInfo.vue'
import EmptyCartUnpaidOrderInfo from './EmptyCartUnpaidOrderInfo.vue'
import { daEventCenter } from '@/public/src/services/eventCenter'

const props = defineProps({
  orderInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(['viewOrder'])

const onClick = () => {
  emit('viewOrder', { type: '0', orderInfo: props.orderInfo })
}

onMounted(() => {
  daEventCenter.triggerNotice({
    daId: '1-8-7-15',
    extraData: {
      order_id: props.orderInfo?.billno || ''
    }
  })
})
</script>

<style lang="less" scoped>
.ecu-order {
  padding: 0 12/37.5rem 12/37.5rem;
  width: 100%;
  background: #fff;
  .ecu-order__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10/37.5rem;
    width: 100%;
    background: #F6F6F6;
  }
}
</style>
