<template>
  <div
    class="ecu-order-v2"
    @click="onClick"
  >
    <div class="ecu-order-v2__content">
      <header class="ecu-order-v2__header">
        <span class="ecu-order-v2__title">{{ orderInfo.waitingPaymentTip }}</span>
        <div
          v-if="totalAmountWithSymbol"
          class="ecu-order-v2__total"
        >
          <span class="ecu-order-v2__total-label">{{ language.SHEIN_KEY_PWA_30842 }}:</span>
          <span class="ecu-order-v2__total-value">{{ totalAmountWithSymbol }}</span>
        </div>
      </header>
      <section class="ecu-order-v2__bottom">
        <div
          v-for="(img, i) in goodsImgList"
          :key="i"
          class="ecu-order-v2__produc"
        >
          <img
            :src="img"
            alt=""
          />
          <div
            v-if="count > 4 && i === 3"
            class="ecu-order-v2__produc-overlay"
          >
            +{{ overCount }}
          </div>
        </div>
        <div class="ecu-order-v2__center">
          <div
            v-if="savePriceAmountWithSymbol"
            class="ecu-order-v2__saved"
          >
            <div class="ecu-order-v2__saved-label">
              {{ language.SHEIN_KEY_PWA_30913 }}:
            </div>
            <div class="ecu-order-v2__saved-value">
              {{ savePriceAmountWithSymbol }}
            </div>
          </div>
          <CountDown
            v-if="showCountDown"
            :timeStamp="orderInfo.expireCountdown"
            :styleConfig="{
              '--font-color': '#fff',
              '--num-bg-color': '#FA6338',
              '--divider-color': '#FA6338',
              '--divider-font-weight': 'bold'
            }"
          />
        </div>
        <sui_icon_viewmore_right_40px
          size="24px"
          class="ecu-order-v2__button"
          :is-rotate="locals.GB_cssRight"
        />
      </section>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { sui_icon_viewmore_right_40px } from '@shein-aidc/icon-vue2'
import { daEventCenter } from '@/public/src/services/eventCenter'
import CountDown from 'public/src/pages/cartNew/components/cartActivityNew/components/CountDown.vue'

const emit = defineEmits(['viewOrder'])
const props = defineProps({
  orderInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({})
  }
})

const totalAmountWithSymbol = computed(() => {
  return props.orderInfo?.totalPrice?.amountWithSymbol
})
const savePriceAmountWithSymbol = computed(() => {
  return props.orderInfo?.savedPrice?.amount > 0 ? props.orderInfo?.savedPrice?.amountWithSymbol : ''
})
const goodsImgList = computed(() => props.orderInfo?.goodsImgList?.slice(0, 4) || [])
const count = computed(() => props.orderInfo?.goodsImgList?.length || 0)
const overCount = computed(() => {
  if (props.orderInfo?.goodsNum) {
    return Math.min(props.orderInfo?.goodsNum - 3, 99)
  }
  return ''
})
const showCountDown = computed(() => {
  return props.orderInfo?.expireCountdown >= (new Date().getTime()) / 1000
})

const onClick = () => {
  emit('viewOrder', { type: '0', orderInfo: props.orderInfo })
}

onMounted(() => {
  daEventCenter.triggerNotice({
    daId: '1-8-7-15',
    extraData: {
      order_id: props.orderInfo?.billno || ''
    }
  })
})
</script>

<style lang="less" scoped>
.ecu-order-v2 {
  padding: 0 12/37.5rem 12/37.5rem;
  width: 100%;
  background: #fff;
  .ecu-order-v2__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10/37.5rem;
    width: 100%;
    background: #F6F6F6;
  }
  .ecu-order-v2__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  .ecu-order-v2__title {
    flex-shrink: 8888;
    font-weight: 700;
    color: #161616;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ecu-order-v2__total {
    min-width: 0;
    display: inline-flex;
    align-items: center;
    margin-left: 8/37.5rem;
    font-size: 14px;
    .ecu-order-v2__total-label {
      flex-shrink: 9;
      font-weight: 700;
      color: #161616;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ecu-order-v2__total-value {
      flex-shrink: 1;
      font-weight: 600;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ecu-order-v2__bottom {
    margin-top: 6/37.5rem;
    display: flex;
    align-items: center;
    line-height: normal;
  }
  .ecu-order-v2__produc {
    flex-shrink: 0;
    position: relative;
    width: 54/37.5rem;
    height: 54/37.5rem;
    &:not(:last-child) {
      margin-right: 6/37.5rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .ecu-order-v2__produc-overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      font-family: "PingFang SC";
      line-height: normal;
    }
  }
  .ecu-order-v2__center {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 0;
    flex: 1;
    ::v-deep(.cart-count-down) {
      display: inline-flex;
    }
  }
  .ecu-order-v2__saved {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: right;
    font-size: 12px;
  }
  .ecu-order-v2__saved-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ecu-order-v2__saved-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    color: #FA6338;
  }
  .ecu-order-v2__button {
    margin-left: 2/37.5rem;
  }
}
</style>
